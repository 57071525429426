import React, { useContext, useEffect, useState } from "react";
import { 
    CheckLatin, 
    CommunityLogo, 
    DescriptionLevel, 
    EmailRegExp, 
    Industries, 
    MergeArrays, 
    SetDict 
} from "../elements/Data";
import { 
    AngleRightBlack,
    FacebookBlack, 
    LinkedInBlack, 
    PhoneBlack, 
    PlusBlack,   
    PlusGreen,  
    PlusWhite,
    TelegramBlack,
    TrashBlack, 
} from "../elements/Imgs";
import { ProfileUpdate } from "../Requests";
import {  
    Loading, 
} from "../elements/Elements.jsx";
import { 
    InfoErrorPopup, 
    InfoPopup,
    PopupCenterChangeEmail, 
} from "../elements/Popups";
import { GlobalContext, ProfileContext } from "../elements/Contexts";
import { FormattedMessage } from "react-intl";
import { messages } from "../../i18n/messages";

const EditProfileMobile = (props: any) => {
    const context = useContext(ProfileContext);
    const globalContext = useContext(GlobalContext);

    const [ avatar_, setAvatar_ ] = useState<File | null>(null);
    const [ avatarUrl, setAvatarUrl ] = useState<string>('')

    const [ profile_, setProfile_ ] = useState<any>(props.profile);  

    const [ waitUpdate, setWaitUpdate ] = useState<boolean>(false);    
    // const [ addLang, setAddLang ] = useState<boolean>(false);  
    // const [ newLang, setNewLang ] = useState<string>('');     
    const [ addIndustry, setAddIndustry ] = useState<boolean>(false);  
    const [ newIndustry, setNewIndustry ] = useState<string>('');   
    const [ editOkPopup, setEditOkPopup ] = useState<boolean>(false);
    const [ errorPopup, setErrorPopup ] = useState<boolean>(false);
    const [ confirmEmailPopup, setConfirmEmailPopup ] = useState<boolean>(false);

    const [ initEmail, setInitEmail ] = useState<string>(props.profile?.links?.email);

    const [ isInvalid, setIsInvalid ] = useState<boolean>(false);
    const popularIndustries = [
        "Impact", 
        "Tech", 
        "AI", 
        "Media/Content", 
        "Future of Work", 
        "Education", 
        "FinTech", 
        "E-commerce", 
        "MarketingTech", 
        "Web3", 
        "Social Networks", 
        "Consulting", 
    ]

    const hideIndustryList = () => {
        setNewIndustry(''); 
        setAddIndustry(false);
    }

    useEffect(() => {
        setAvatarUrl(avatar_ ? URL.createObjectURL(avatar_) : ``);
    }, [avatar_])

    useEffect(() => {
        if (!avatarUrl && !props?.profile?.avatar?.link && props?.profile?.avatar?.style < 2) {
            setProfile_((val: any) => SetDict(val, ['avatar', 'style'], 2))
        }    
    }, [avatarUrl, props.profile])
    
    useEffect(() => {
        if (props.profile) {  
            setAvatarUrl(avatar_ ? URL.createObjectURL(avatar_) : props.profile?.avatar?.link) 
            setProfile_(props.profile); 
            setInitEmail(val => val ?? props.profile?.links?.email);
        }
    }, [props.profile])

    useEffect(() => {
        context.setIsEdit(true);
        setIsInvalid(false);

        if (CommunityLogo[globalContext.community]?.redirect_on_edit) {
            window.location.href = CommunityLogo[globalContext.community]?.redirect_on_edit;
        }

        return () => {
            context.setIsEdit(false);
        }
    }, [])

    return (
        <div className={`px-3 pt-3`}>
            <button className={`btn-circled-24 mobile p-left row left mb-2`} 
                    onClick={() => { 
                        props.setLeaveFunc(((val: any) => SetDict(val, [`back`], true)));
                        props.setShowConfirmLeavePopup(true); 
                    }}>
                <img src={AngleRightBlack} alt={`>`} className={`w-3`} style={{transform: `rotate(180deg)`}}></img>
                <span data-size={5} className={`semibold custom-hover`}>
                    <FormattedMessage id="profile"/>
                </span>
            </button>

            <div className={`block block p-3 mb-2 b-3 w-flex`} data-invalid-form={isInvalid}>
                <p className={`text-5 semibold mb-2`}>
                    <FormattedMessage id="ob_photo_header"/>*
                </p>
                <div className={`row top nowrap nogap block b-2 ${avatarUrl ? "valid" : "invalid"} w-flex`} data-color={`light-gray`} style={{
                        height: `auto`, aspectRatio: 1,
                    }} id={`registration-image-field`}>
                    <input id={`input-img`} className={``} type={`file`} accept={`image/png, image/jpeg, image/gif`}
                        onChange={e => {
                            if (e.target.files?.length) {
                                setAvatar_(e.target.files[0]);
                            }
                        }} name={`preview`}></input>
                    {!!avatarUrl ? <>
                        <div className={`w-flex h-flex b-2`} style={{
                                backgroundImage: `url(${avatarUrl})`,
                            }}></div> 
                        <button className={`block btn mobile neg-ml-5 mt-2 mr-2 w-4 h-4 b-1 centered`}
                                onClick={() => { 
                                    setAvatar_(null); 
                                    setAvatarUrl(``);
                                    (document.querySelector(`#input-img`) as HTMLInputElement).value = '';
                                }} type={`button`}>
                            <img src={TrashBlack} alt={``} className={`w-3 h-3`}></img>
                        </button>                                                               
                    </> :
                    <div className={`w-flex h-flex centered`}>
                        <button className={`block btn-flex mobile centered`} data-color={`gray`} type={`button`}
                                onClick={() => { (document.querySelector(`#input-img`) as HTMLInputElement).click() }}>
                            <p data-color={`black`}><FormattedMessage id="edit_upload_photo"/></p>
                        </button>
                    </div>}
                </div> 
            </div>

            <div className={`block block p-3 mb-2 b-3 w-flex`} data-invalid-form={isInvalid}>
                <p className={`text-5 semibold mb-2 px`}>
                    <FormattedMessage id="fname"/>*
                </p>
                <input className={`mobile mb-3 ${(profile_?.fname ?? '').trim().length < 2 ? 'in' : ''}valid ${CheckLatin(profile_?.fname) ? 'only-latin' : ''}`} 
                        placeholder={messages[globalContext.locale].fname_ph} type={`text`} required={true} id={`nameInput`}
                        onChange={e => {
                            setProfile_((val: any) => SetDict(val, ['name'], `${e.target.value.trim()} ${(val.lname ?? '').trim()}`));
                            setProfile_((val: any) => SetDict(val, ['fname'], e.target.value));
                        }} value={profile_?.fname ?? ""} data-size={6}></input> 
                <p className={`after neg-mt-3 mb-3 pt`} data-color={`coral`}></p>

                <p className={`text-5 semibold mb-2 px`}>
                    <FormattedMessage id="lname"/>*
                </p>
                <input className={`mobile mb-3 ${(profile_?.lname ?? '').trim().length < 2 ? 'in' : ''}valid ${CheckLatin(profile_?.lname) ? 'only-latin' : ''}`} 
                        placeholder={messages[globalContext.locale].lname_ph} type={`text`} required={true} id={`nameInput`}
                        onChange={e => {
                            setProfile_((val: any) => SetDict(val, ['name'], `${(val.fname ?? '').trim()} ${e.target.value.trim()}`));
                            setProfile_((val: any) => SetDict(val, ['lname'], e.target.value));
                        }} value={profile_?.lname ?? ""} data-size={6}></input> 
                <p className={`after neg-mt-3 mb-3 pt`} data-color={`coral`}></p>

                <p className={`text-5 semibold mb-2 px`}>
                    {`Email`}*
                </p>
                <input className={`mobile ${!EmailRegExp.test(profile_?.links?.email) ? 'in' : ''}valid ${CheckLatin(profile_?.links?.email) ? 'only-latin' : ''}`} type={`email`} required={true}
                        value={profile_?.links.email ?? ""} data-size={6} onChange={e => {
                            setProfile_((val: any) => SetDict(val, [`links`, `email`], e.target.value));
                        }}></input> 
                <p className={`after pt`} data-color={`coral`}></p>

                {/* <p className={`text-5 semibold mb-2 px`}>
                    {`LinkedIn*`}
                </p>
                <div className={`row input-block mobile ${!profile_?.links.linkedin ? 'in' : ''}valid`}>
                    <input className={`mobile`} placeholder={`LinkedIn`} type={`text`} data-size={6}
                        value={profile_?.links.linkedin ?? ""} onChange={e => {setProfile_((val: any) => SetDict(val, ['links', 'linkedin'], e.target.value))}}></input> 
                    <img className={`w-3`} src={LinkedInLogo} alt={`li`}></img>
                </div>
                <p className={`after pt`} data-color={`coral`}><FormattedMessage id="required_field"/></p> */}
                   
                {[
                    {title: `LinkedIn`, logo: LinkedInBlack},
                    {title: 'WhatsApp', logo: PhoneBlack},
                    // {title: 'iMessage', logo: AppleBlack},
                    {title: 'Facebook', logo: FacebookBlack},
                    {title: 'Telegram', logo: TelegramBlack},
                ].map((elem, index) => {return (<div key={index}>
                    {(profile_?.links[elem.title.toLowerCase()] || profile_?.links[`${elem.title.toLowerCase()}Input`]) ? 
                    <>
                        <p className={`text-5 semibold mb-2 mt-2`}>
                            {elem.title}
                        </p>
                        <div className={`row input-block mobile`}>
                            <input className={`mobile`} placeholder={elem.title} type={`text`} id={`waInput`}
                                onChange={e => {
                                    setProfile_((val: any) => SetDict(val, ['links', `${elem.title.toLowerCase()}Input`], true));
                                    setProfile_((val: any) => SetDict(val, ['links', elem.title.toLowerCase()], e.target.value));
                                }} value={profile_?.links[elem.title.toLowerCase()] ?? ""} data-size={6}></input> 
                            <img className={`w-3`} src={elem.logo} alt={``}></img>
                        </div>
                    </> : 
                    <div className={`row left mt-2 noscroll px`} onClick={() => {
                            setProfile_((val: any) => SetDict(val, ['links', `${elem.title.toLowerCase()}Input`], true))
                        }}>
                        <div className={`btn-symbol w-3 h-3 centered`} data-color={`black`}>
                            <img src={PlusBlack} alt={`+`}></img>
                        </div>    
                        <p className={`text-6 semibold`}>{`Add ${elem.title}`}</p>                      
                    </div>}
                </div>)})}
            </div>

            <div className={`block block p-3 mb-2 b-3 w-flex`} data-invalid-form={isInvalid}>
                <p className={`text-5 semibold mb-2 px`}>
                    <FormattedMessage id="industry"/>*
                </p>
                {addIndustry ? <>  
                <input  value={newIndustry} onChange={e => {
                        setNewIndustry(e.target.value);
                    }} onKeyDown={e => {
                        if (e.key === "Enter") {
                            e.preventDefault();
                            e.stopPropagation();
                            if (newIndustry) {
                                setProfile_((val: any) => SetDict(
                                    val, ["industry"],
                                    val?.industry.includes(newIndustry.trim()) ? val?.industry : val?.industry.concat([newIndustry.trim()])
                                ))
                                hideIndustryList();
                            }
                        }
                    }} onFocus={e => {
                        setAddIndustry(true);
                    }} className={`mobile mb-2`} data-size={6} placeholder={messages[globalContext.locale].industry_ph}
                    style={{
                        lineHeight: `30px`, paddingTop: 14, paddingBottom: 14,
                    }} id={`input-industry`}></input>               
                <div className={`row left mb-2`}>
                    {((
                        Industries.filter((val: string) => val.toLowerCase().includes(newIndustry.toLocaleLowerCase().trim()))?.length &&
                        newIndustry.trim()?.length
                    ) ?
                    MergeArrays(profile_?.industry ?? [], Industries.filter((val: string) => val.toLowerCase().includes(newIndustry.toLocaleLowerCase().trim()))) : (profile_?.industry?.length ? profile_?.industry : popularIndustries))
                    .map((elem: string, index: number) => {return (
                        <button className={`block btn-circled-32 mobile row`} 
                                data-color={profile_?.industry.includes(elem) ? 'transparent' : `light-gray`}
                                data-border={profile_?.industry.includes(elem) ? `green-1` : null}
                                onClick={() => {
                                    setProfile_((val: any) => SetDict(
                                        val, ["industry"],
                                        val?.industry.includes(elem) ? val?.industry.filter((val_: string) => val_ !== elem) : val?.industry.concat([elem])
                                    ))
                                    const input = document.querySelector(`#input-tag`) as HTMLInputElement;
                                    if (input) {
                                        input.focus();
                                    }
                                }} type={`button`} id={`select-btn`} key={index}>
                            <p data-color={profile_?.industry.includes(elem) ? 'green' : `black-40-opacity`}>
                                {elem}
                            </p>
                            {profile_?.industry.includes(elem) &&
                            <img src={PlusGreen} alt={`+`} style={{transform: `rotate(45deg)`}} className={` neg-mr w-2`}></img>}
                        </button>
                    )})}
                </div>
                <button className={`block btn-circled-32 mobile row center w-7`} onClick={e => {
                        e.stopPropagation();
                        hideIndustryList();
                    }} data-color={CommunityLogo[globalContext.community]?.custom_color ?? `black`} type={`button`}>
                        <p><FormattedMessage id="apply"/></p>
                </button>
                </> : <>
                <div className={`row left mb-1`} hidden={!profile_?.industry?.length}>
                    {profile_?.industry.map((elem: string, index: number) => 
                    <button className={`block btn-circled-32 mobile row`} data-color={`transparent`} data-border={`green-1`}
                            onClick={() => {
                                setProfile_((val: any) => SetDict(
                                    val, [`industry`],
                                    val?.industry.filter((elem_: string) => elem_ !== elem)
                                )) 
                            }} key={index} type={`button`}>
                        <p data-color={`green`}>{elem}</p>
                        <img src={PlusGreen} alt={`+`} style={{transform: `rotate(45deg)`}} className={` neg-mr w-2`}></img>
                    </button>)}
                </div>
                <button className={`block btn-circled-32 desktop p-left row left noscroll nowrap`} onClick={e => {
                    e.stopPropagation();
                    setAddIndustry(true);
                }} data-color={CommunityLogo[globalContext.community]?.custom_color ?? `black`} type={`button`}>
                    <div className={`btn-symbol w-3 h-3 centered`} data-color="white">
                        <img src={PlusWhite} alt={`+`}></img>
                    </div>
                    <p><FormattedMessage id="add_industry"/></p>
                </button></>}
                <p className={`after pt`} data-color={`coral`}></p>

                <p className={`text-5 semibold mb-2 mt-3 px`}>
                    <FormattedMessage id="ob_occupation_company"/>*
                </p>
                <input className={`mobile mb-3 ${!profile_?.company ? 'in' : ''}valid ${CheckLatin(profile_?.company) ? 'only-latin' : ''}`} data-size={6}
                        placeholder={messages[globalContext.locale].occupation_company_ph}
                        required={true} value={profile_?.company ?? ""} 
                        onChange={e => {setProfile_((val: any) => SetDict(val, ['company'], e.target.value))}}></input>
                <p className={`after neg-mt-3 mb-3 pt`} data-color={`coral`}></p>

                <p className={`text-5 semibold mb-2 px`}>
                    <FormattedMessage id="ob_occupation_role"/>*
                </p>
                <input className={`mobile mb-3 ${!profile_?.occupation ? 'in' : ''}valid ${CheckLatin(profile_?.occupation) ? 'only-latin' : ''}`} data-size={6}
                        placeholder={`Enter your role in the company`} 
                        required={true} value={profile_?.occupation ?? ""} 
                        onChange={e => {setProfile_((val: any) => SetDict(val, ['occupation'], e.target.value))}}></input>
                <p className={`after neg-mt-3 mb-3 pt`} data-color={`coral`}></p>

                <p className={`text-5 semibold px`}>
                    <FormattedMessage id="profile_tell_about"/>*
                </p>
                <p className={`text-6 semibold px my-1`} data-color={`black-40-opacity`}>
                    {`Tell about yourself in a way that would interest the person you're looking for`}
                </p>
                <textarea className={`p-2 b-1 h-8 mb-3 ${!DescriptionLevel(profile_?.about)?.isValid ? 'in' : ''}valid`} data-size={6} 
                        placeholder={`Describe yourself...`} 
                        required={true} value={profile_?.about ?? ""} 
                        onChange={e => {setProfile_((val: any) => SetDict(val, ['about'], e.target.value))}}></textarea> 
                <div className={`block h w-flex mt b row left`} data-color={`light-gray`}>
                    <div className={`h-flex b`} style={DescriptionLevel(profile_?.about)?.style}></div>
                </div>
                {DescriptionLevel(profile_?.about)?.isText &&
                <p data-size={6} className={`mt`}
                    data-color={!DescriptionLevel(profile_?.about)?.isValid ? `coral` : `black-40-opacity`}>
                    <FormattedMessage id={DescriptionLevel(profile_?.about)?.locale_text}/>
                    {/* {DescriptionLevel(profile_?.about)?.text} */}
                </p>}

                <p className={`text-5 semibold mb-2 px`}>
                    <FormattedMessage id="ob_occupation_city"/>*
                </p>
                <input className={`mobile ${!profile_?.location?.city ? 'in' : ''}valid ${CheckLatin(profile_?.location?.city) ? 'only-latin' : ''}`} placeholder={`Your location`} type={`text`} data-size={6}
                    value={profile_?.location.city ?? ""} onChange={e => {setProfile_((val: any) => SetDict(val, ['location', 'city'], e.target.value))}}></input> 
                <p className={`after pt`} data-color={`coral`}></p>
            </div>
{/*             
            <div className={`block block p-3 mb-3 b-3 w-flex`}>
                <p className={`text-4 semibold mb-2`}><FormattedMessage id="edit_profile_style"/></p>
                <div className={`row x-auto left nowrap noshrink w-flex-p-3 neg-mx-3 px-3 py-1 neg-my-1`}>
                    {ProfileStyles.map((elem, index) => {return ((avatarUrl || props?.profile?.avatar?.link || (!!(index - 1) && !!index)) ?                   
                    <button className={`column w-6 top btn-checkbox desktop custom-style`} style={{position: `relative`}}
                            onClick={() => {setProfile_((val: any) => SetDict(val, ['avatar', 'style'], index))}} key={index}>
                        <NewAvatar size={6} type={index} name={profile_?.name} className={` circled`}
                                avatar={(!!avatarUrl ? avatarUrl : props.profile?.avatar?.link) ?? ' '}/>  
                        {index === profile_?.avatar?.style &&
                        <div style={{
                            right: -5, bottom: -5, top: -5, left: -5,
                            position: `absolute`, borderRadius: 19,
                        }} className={`block`} data-color={`tp-blue-3`}></div>}                          
                    </button> : null
                    )})}
                </div>
            </div> */}

            <div className={`sticky-bottom-`}>
                <div className={`row visible pb-3`}>
                    <button className={`block btn-flex mobile row center w-flex`} data-color={CommunityLogo[globalContext.community]?.custom_color ?? `black`}
                            onClick={() => {
                                setWaitUpdate(false);
                                setIsInvalid(true);
                                const invalidElem = document.querySelector(`input.invalid, textarea.invalid, .invalid input, .invalid textarea, div.invalid, input.only-latin, textarea.only-latin, .only-latin input, .only-latin textarea, div.only-latin`);
                                if (invalidElem) {
                                    (invalidElem as HTMLInputElement).focus();
                                    window.scrollTo({
                                        top: invalidElem.getBoundingClientRect().top + window.scrollY - 70,
                                        behavior: 'smooth',
                                    });
                                } else {
                                    setWaitUpdate(true);
                                    ProfileUpdate(profile_?.id, profile_, avatar_, () => {
                                        setWaitUpdate(false);
                                        context.setIsEdit(false);
                                        context.UpdateData();
                                        setWaitUpdate(false);
                                        if (profile_?.links?.email === initEmail) {
                                            context.setTabRaw(0);
                                        } else {
                                            setConfirmEmailPopup(true);
                                        }
                                    }, () => { 
                                        setWaitUpdate(false);
                                        setErrorPopup(true);
                                    });
                                }
                            }}>
                        {waitUpdate ?
                        <Loading color={`white`} width={18} height={18}/> :
                        <p><FormattedMessage id="settings_profile_save"/></p>}
                    </button>
                </div>
            </div>
            
            {editOkPopup &&
            <InfoPopup show={editOkPopup} setShow={setEditOkPopup}
                    title={`Your updates saved`}
                    buttonTitle={<FormattedMessage id="ok"/>} onOk={() => {setEditOkPopup(false)}} mobile={true}/>}

            {errorPopup &&
            <InfoErrorPopup show={errorPopup} setShow={setErrorPopup}
                            title={<FormattedMessage id="reg_error"/>}
                            buttonTitle={`Return to profile`} onOk={() => {
                                    setErrorPopup(false);
                                    context.setIsEdit(false);
                                    context.UpdateData();
                                    context.setTabRaw(0);
                            }} mobile={true}/>}

            {confirmEmailPopup &&
            <PopupCenterChangeEmail show={confirmEmailPopup} setShow={setConfirmEmailPopup} mobile={true} mailbox={null}/>}
        </div>
    )
}

export {
    EditProfileMobile,
}